import React, { createContext, useState, useContext, useCallback } from 'react';
import Snackbar from './snackbar';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({ message: '', severity: '', isOpen: false });
  const showSnackbar = useCallback((message, severity) => {
    const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1);
  
    setSnackbar({ message: capitalizedMessage, severity, isOpen: true });
  }, []);
  

  const closeSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, isOpen: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      {snackbar.isOpen && (
        <Snackbar
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={closeSnackbar}
        />
      )}
    </SnackbarContext.Provider>
  );
};
