import React from "react";
import { Link } from "react-router-dom";
import errorimg from "../media/error.png";

export const NotFound = () => {
  return (
    <>
      <div className="min-h-screen flex justify-center items-center">
        <div className="flex flex-col items-center">
          <img src={errorimg} alt="" className="w-1/5" />
          <h1 className="text-3xl font-light capitalize mt-4">Page not found.</h1>
          <p className="font-sans mt-2">Oops! There seems to be a mistake in the URL.</p>
          <p className="font-sans mt-2">
            Navigate back to the{" "}
            <span className="text-blue-500">
              <Link to="/">homepage</Link>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
