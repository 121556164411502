import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import video1 from "../../media/hero/day.mp4";
import video2 from "../../media/hero/evening.mp4";
import video3 from "../../media/hero/night.mp4";
import { LoadingScreen } from "./loading";
import image1 from '../../media/hero/image1.jpg'

import image2 from '../../media/hero/image2.webp'
const HeroPage = () => {
  const textRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const videos = [ video2,video1, video3]; 

  useEffect(() => {
    if (textRefs.current.length === 0) return;

    gsap.from(textRefs.current[0], {
      opacity: 0,
      y: 500,
      duration: 3,
      ease: "power3.out"
    });

    gsap.from(textRefs.current[1], {
      opacity: 0,
      y: 500,
      duration: 3,
      ease: "power3.out",
      delay: 0.5 
    });

    gsap.from(textRefs.current[2], {
      opacity: 0,
      y: 500,
      duration: 3,
      ease: "power3.out",
      delay: 1 
    });
  }, [textRefs.current]);

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  return (
    <section id="/">
      {isLoading && <LoadingScreen />}
      <div className="grid grid-cols-1 gap-4 justify-center items-center relative" >
        <Carousel infiniteLoop autoPlay showIndicators={false} transitionTime={2000} interval={9000} showStatus={false} showArrows={false} showThumbs={false}>
          {videos.map((video, index) => (
            <div key={index} className="carousel-video-wrapper">
              <video
                src={video}
                alt={`Carousel Video ${index}`}
                className="w-full h-full object-cover"
                autoPlay
                loop
                muted
                style={{ backgroundSize: "cover", height: "100vh" }}
                onLoadedData={handleVideoLoaded}
              />
            </div>
          ))}
        </Carousel>
        
        <div className="absolute inset-0 bg-black opacity-50 h-full"></div>
        <div className="absolute inset-0 flex justify-center items-center z-10 md:p-10 lg:p-20">
          <div className="text-center text-white">
            <h1 className="lg:text-5xl md:text-5xl text-4xl" ref={(el) => (textRefs.current[0] = el)}>
              EXPLORE
            </h1>
            <h1 className="lg:text-8xl md:text-5xl text-4xl mt-2 font-bold" ref={(el) => (textRefs.current[1] = el)}>
              THE NEW WORLD
            </h1>
            <p className="text-xl mt-2" ref={(el) => (textRefs.current[2] = el)}>
              Adventure & Experience The Travel!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroPage;
