import React from "react";
import "./App.css";
import { Home } from "./components/home/Home";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "./utils/AuthContext";
import TimeoutDialog from "./utils/timeout";

import MainLayout from "./components/layouts/MainLayout";

import { NotFound } from "./pages/Error";
import    FloatingButton   from "./utils/FloatingButton";
import { Footer } from "./components/others/Footer";
import { Navbar } from "./components/others/Navbar";
import LocationPage from "./components/locations/individuallocation";
function App() {
  const {
    openTimeOutDialog,
    refreshAccessToken,
    handleIgnore
  } = useAuth();

  return (
    <div className="App">
         <FloatingButton />
      <Router>
        <Routes>
        

<Route
          path="/*"
          element={
            <MainLayout>
               <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
              
                <Route path="locations/:place" element={<LocationPage />} />
             <Route path="*" element={<MainLayout><NotFound /></MainLayout>} />
              </Routes>
              <Footer />
            </MainLayout>
          }
        />
       
             
   
        </Routes> 

        <TimeoutDialog
          isOpen={openTimeOutDialog}
          onClose={handleIgnore}
          onRefresh={refreshAccessToken}
        />
      </Router>
    </div>
  );
}

export default App;
