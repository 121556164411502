import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './utils/AuthContext';
import { SnackbarProvider } from './utils/snackbarprovider';
import ReactGA from "react-ga4";
ReactGA.initialize("G-PBLHNTRPB0");
ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
ReactDOM.render(
<AuthProvider>
<SnackbarProvider>
    <App />
    </SnackbarProvider>
    </AuthProvider>  ,
    document.getElementById('root')
);

