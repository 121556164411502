import React, { useRef, useState } from 'react';
import { FaFire } from 'react-icons/fa';

const DateAvailable = ({   }) => {
  const scrollContainerRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  
  const filteredGroupedDates = {
    'September 2024': [
      { id: 1, month: 'Sep', year: '2024', published_status: 'Inactive', hot: true },
      { id: 3, month: 'Oct', year: '2024', published_status: 'Inactive', hot: true },
      { id: 5, month: 'Nov', year: '2024', published_status: 'Inactive', hot: true },
    ],
    // 'October 2024': [
    //   { id: 3, month: 'Oct', year: '2024', published_status: 'Inactive', hot: true },
    // ],
    // 'November 2024': [
    //   { id: 5, month: 'Nov', year: '2024', published_status: 'Inactive', hot: true },
    // ]
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-white rounded-lg border mt-2 w-full p-4 relative">
  
      <div className="mb-4">
        <h2 className="text-lg font-semibold text-gray-800">Travel Departure</h2>
        {/* <p className="text-gray-600">September, October, November 2024</p> */}
      </div>

      <hr className="my-2" />

      <div className="relative overflow-hidden flex items-center">
        <button className="p-2" onClick={() => handleScroll('left')}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-800">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5l-7.5-7.5 7.5-7.5" />
          </svg>
        </button>

        <div ref={scrollContainerRef} className="flex space-x-4 overflow-x-hidden">
       { Object.keys(filteredGroupedDates).map((monthYear) => (
              <div key={monthYear} className="flex flex-none">
                <div className="bg-gray-600 flex items-center px-2 h-full text-white rounded-lg text-center mr-4">
                  <div>
                    {/* <p className="text-sm">{filteredGroupedDates[monthYear][0]?.month}</p> */}
                    <p className="text-sm">{filteredGroupedDates[monthYear][0]?.year}</p>
                  </div>
                </div>
                <div className="flex flex-wrap gap-4">
                  {filteredGroupedDates[monthYear]?.map((date) => (
                    <div className='flex relative cursor-pointer' key={date.id} onClick={() => setSelectedDate(date.id)}>
                      {date.hot && (
                        <FaFire
                          className="absolute top-0 right-0 animate-pulse text-red-700"
                          style={{ filter: 'drop-shadow(0 0 5px rgba(255, 0, 0, 0.75))' }}
                        />
                      )}
                      <div className={`${selectedDate === date.id ? 'bg-blue-500'  : date.published_status === 'Active' ? 'bg-[#5cac33] text-white' :'bg-white border border-gray-300' } h-full rounded-lg text-center`}>
                        <p className={`${selectedDate === date.id ? 'text-white' : date.published_status === 'Active' ? 'bg-[#5cac33] ' : 'bg-gray-100 text-gray-700'  } uppercase text-sm rounded-t-lg`}>
                          {date?.month}
                        </p>
                        <div className="px-3">
                          <hr className="border-gray-300" />
                          {/* <p className={`${selectedDate === date.id ? 'text-white' :  ''}`}>{date?.date}</p> */}
                          <p className={`${selectedDate === date.id ? 'text-white' : date.published_status === 'Active' ? 'text-white ' :'text-gray-500'} text-sm mb-2`}>{date?.year}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          }
        </div>

        <button className="absolute bg-white h-full right-0 top-1/2 transform -translate-y-1/2 p-2" onClick={() => handleScroll('right')}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-800">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>
      </div>
  
    </div>
  );
};

export default DateAvailable;
