import React, { useEffect, useState } from 'react';

import video1 from '../../media/video/148316-793718148_small.mp4';
import video2 from '../../media/video/31569-387675206_small.mp4';
import video3 from '../../media/video/37088-413229662_small.mp4';
import video4 from '../../media/video/85763-590014863_small.mp4';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const videos = [video1, video2, video3, video4];

const PlayVideo = () => {


  
  return (
    <div className="relative w-full">
      {/* <video
        id="video-player"
        key={currentVideo}
        src={videos[currentVideo]}
        autoPlay
        muted
        className={`absolute w-full h-full object-cover transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}
      /> */}

<Carousel infiniteLoop autoPlay showIndicators={false} transitionTime={2000} interval={9000} showStatus={false} showArrows={false} showThumbs={false}>
        {videos.map((video, index) => (
          <div key={index} className="carousel-video-wrapper">
            <video src={video} alt={`Carousel Video ${index}`} className="w-full h-full object-cover" autoPlay  loop muted />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default PlayVideo;
