import React, { useState } from 'react';
import backgroundImage from '../../media/hero/background.png';
import airplane from '../../media/whyus/airplane.jpg';
import gateway from '../../media/whyus/gateway.jpg'; 
import resort from '../../media/whyus/resort.jpg'; 
import holiday from '../../media/whyus/holiday.jpg'; 
import { Disclosure,Dialog, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Fragment } from 'react';
import comingsoon from '../../media/comingsoon2.jpg'
const WhyUs = () => {

  return (
    <section id='why-us' >
    <div className="relative p-8 lg:p-20  mx-auto">
       
             <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between ">
           
            <div className="mb-10 lg:w-2/3 text-center md:mx-auto">
                <h6 className="font-cursive mb-2 text-lg md:text-xl text-[#1bbc9b] lg:text-3xl line-clamp-3">
                Why Us?
                </h6>
            
                <p className="text-[#6e6e6e]">
                For our members, the next adventure is always just around the corner. In the midst of our busy lives and daily responsibilities, finding time to relax and recharge can be challenging. That's where we come in. Our exclusive travel membership offers destinations, events, and experiences that help you break free from the routine and rediscover what truly matters.    </p>
              </div>
     

          
                 </div>
        
     
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="flex flex-col items-center text-center">
            <img src={airplane} alt="Cruises" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Explore New Countries</h3>
            <p className="text-gray-700 mt-2">
              Our focus on quality resorts, customer service, and flexibility ensures that every trip is a seamless and enjoyable experience. With access to a world of entertainment and activities, our membership guarantees that you’ll always have something to look forward to.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={holiday} alt="Flexible Holidays" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Flexible Holidays</h3>
            <p className="text-gray-700 mt-2">
              Our Flexible Holidays system makes planning your next vacation simple and stress-free, allowing you to shake things up without the hassle. 
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="flex flex-col items-center text-center">
            <img src={gateway} alt="Destinations" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Unforgettable Getaways</h3>
            <p className="text-gray-700 mt-2">
              From unforgettable getaways to exclusive concert series and VIP receptions, our members rely on us to recharge, reconnect, and create lasting memories.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <img src={resort} alt="Concerts" className="w-full h-64 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-semibold text-gray-800">Exclusive Concert Series</h3>
            <p className="text-gray-700 mt-2">
              Whether you're returning to a favorite resort, embarking on a cruise to explore new countries, or attending a once-in-a-lifetime event, you can count on us to deliver quality and exceptional experiences every time.
            </p>
          </div>
        </div>
       
      
      </div>
   </section>
  );
}

export default WhyUs;
