
import React from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'; 

export default function FloatingButton() {
  return (
    <div className="fixed bottom-4 z-50 right-4 flex flex-col gap-2">

      <a
        href="mailto:info@flymyway.in?subject=Enquiry&body=I'm interested in making an enquiry. Please provide more details."
        className="flex items-center bg-[#1bbc9b] text-white px-4 py-2 rounded-full shadow-lg hover:bg-[#16a085] transition-colors duration-300"
      >
        <AiOutlineMail className="mr-2" /> 
        Enquiry
      </a>

  
      <a
        href="tel:+918110013999"
        className="flex items-center bg-[#3498db] text-white px-4 py-2 rounded-full shadow-lg hover:bg-[#2980b9] transition-colors duration-300"
      >
        <AiOutlinePhone className="mr-2" /> 
        Call Us
      </a>
    </div>
  );
}
