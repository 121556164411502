import { useAuth } from "../../utils/AuthContext";

export const Footer = () => {
  const { accessToken } = useAuth();
  return (
    <section id="contact-us">
      <footer className="bg-white ">
        <div className="container px-6 py-12 mx-auto">
          <div className="md:flex md:-mx-3 md:items-center md:justify-between">
            <h1 className="text-xl font-semibold tracking-tight text-gray-800 md:mx-3 xl:text-2xl  ">
              Unlock All Access and start your
              adventure.  Join now!
            </h1>
{/* 
            {!accessToken && (
              <div className="mt-6 md:mx-3 shrink-0 md:mt-0 md:w-auto">
                <a
                  href="/signup"
                  className="inline-flex items-center justify-center w-full px-4 py-2 text-sm text-white duration-300 bg-gray-800 rounded-lg gap-x-3 hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80"
                >
                  <span>Become a Member</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </a>
              </div>
            )} */}
          </div>

          <hr className="my-6 border-gray-200 md:my-10 " />

          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
            <div>
              <p className="font-semibold text-gray-800  ">
                Quick Link
              </p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Home
                </a>
                <a
                  href="#tour-package"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Tour Package
                </a>
                {/* <a
                  href="#membership"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Membership
                </a> */}
                <a
                  href="#reviews"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Reviews
                </a>
              </div>
            </div>

            {/* <div>
              <p className="font-semibold text-gray-800  ">
                Locations
              </p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Singapore
                </a>
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Malaysia
                </a>
           
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Thailand
                </a>
              </div>
            </div> */}

            {/* <div>
              <p className="font-semibold text-gray-800  ">
                Services
              </p>

              <div className="flex flex-col items-start mt-5 space-y-2">
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Translation
                </a>
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Proofreading & Editing
                </a>
                <a
                  href="#"
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                  Content Creation
                </a>
              </div>
            </div> */}

            <div>
              <p className="font-semibold text-gray-800  ">
                Contact Us
              </p>


<div className="flex flex-col items-start mt-5 space-y-2">
                
              
                <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
               info@flymyway.in
               </a>
         
               <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
                (+91) 8110013999
               </a>
               <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
               (+91) 7373661441
               </a>
              </div>
{/* <div class='grid grid-cols-1 md:grid-cols-2  '>

<div className="flex flex-col items-start mt-5 space-y-2">
                
                <a
                 
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                Mr. Kosalram
                </a>
                <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
                 kosal@flymyway.in
               </a>
         
               <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
                +91 9894046762
               </a>
              </div>
              <hr className=" md:hidden my-2 w-full border-t border-[#e4e4e4]" />
             
              <div className="flex flex-col items-start mt-5 space-y-2">
                
                <a
                 
                  className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
                >
                Mr. Magesh.T
                </a>
                <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
                 magesh@flymyway.in
               </a>
          
               <a
                 
                 className="text-gray-600 transition-colors duration-300  hover:underline hover:text-blue-500"
               >
                +91 9840016018
               </a>
              </div>

</div>
               */}
            </div>
          </div>

          <hr className="my-6 border-gray-200 md:my-10 " />

          <div className="flex flex-col items-center justify-between sm:flex-row">
            {/* <a href="#"> */}
              <img className="w-auto h-14" src="/flymywaylogo.png" alt="" />
            {/* </a> */}

            <p className="mt-4 text-sm text-gray-500 sm:mt-0 ">
              © Copyright 2024. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};
