import React, { useState, useEffect } from "react";
import Hero from "./Hero";
import CarouselInfoComponent from "./info";
import ClearPrice from "./clearprice";
import Testimonials from "../testimonials/testimonials";
import { PackageSection } from "../package/PackageSection";
import PlayVideo from "./Video";
import WhyUs from "./whyus";
import { LoadingScreen } from "./loading";


export const Home = () => {


  return (
    <>
      <Hero />
      <WhyUs />
      <PlayVideo />
      <PackageSection />
      <CarouselInfoComponent />
      <Testimonials />
    </>
  );
};
