import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Timeline = ({ itinerary }) => {
    const [openItems, setOpenItems] = useState({});
    const [allItemsOpen, setAllItemsOpen] = useState(false);

    useEffect(() => {
        const initialOpenState = itinerary.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {});
        setOpenItems(initialOpenState);
    }, [itinerary]);

    useEffect(() => {
        const allItemsOpened = Object.values(openItems).every(item => item);
        setAllItemsOpen(allItemsOpened);
    }, [openItems]);

    const toggleItem = (index) => {
        setOpenItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleViewAll = () => {
        const allOpen = itinerary.reduce((acc, _, index) => {
            acc[index] = !allItemsOpen;
            return acc;
        }, {});
        setOpenItems(allOpen);
        setAllItemsOpen(!allItemsOpen);
    };

    return (
        <div>
            <button onClick={handleViewAll} className="mb-4 text-blue-500">
                {allItemsOpen ? "Hide All Days" : "View All Days"}
            </button>
            <ol className="relative border-l border-dashed border-[#595959]">
                {itinerary.map((day, index) => {
                    const title = day.title;
                    const description = day.description;
                    const image = day.image;

                    return (
                        <li key={index} className="ms-4 relative mb-10">
                            <div className="absolute w-7 h-7 bg-white rounded-full -start-4 transform -translate-x-1/2 border border-white ">
                                <span className="inline-block">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke={openItems[index] ? "#29499A" : "#595959"}
                                        className="h-6 w-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="flex-1">
                                    <time className="mb-1 text-sm font-normal leading-none text-gray-400 ">
                                        Day {index + 1}
                                    </time>
                                    <h3 className={`text-lg font-semibold ${openItems[index] ? "text-[#29499A]" : "text-gray-900"} `}>
                                        {title}
                                    </h3>
                                </div>

                                <button onClick={() => toggleItem(index)} className="focus:outline-none inline-block rounded-full p-2 bg-[#f5f5f5]">
                                    {openItems[index] ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            <div className="flex items-start mt-4">
                                <div className="flex-1">
                                    <p
                                        style={{
                                            transition: 'height 0.3s ease, opacity 0.3s ease',
                                            overflow: 'hidden',
                                            opacity: openItems[index] ? 1 : 0,
                                            maxHeight: openItems[index] ? '1000px' : '0',
                                            paddingTop: openItems[index] ? '1rem' : '0',
                                            paddingBottom: openItems[index] ? '1rem' : '0',
                                            marginBottom: openItems[index] ? '1rem' : '0',
                                            fontSize: '0.875rem'
                                        }}
                                        className="font-normal text-gray-500 text-lg" dangerouslySetInnerHTML={{ __html: description }}
                                    />
                                </div>
                                {image && openItems[index] && (
                                    <div className="ml-4">
                                        <img src={image} alt={`Day ${index + 1}`} className="w-32 h-32 object-cover rounded-lg shadow-md" />
                                    </div>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default Timeline;
