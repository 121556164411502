import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaCalendarAlt, FaRupeeSign, FaTimes,FaUtensils, FaBed, FaCar, FaMoneyBillWave, FaCheck } from "react-icons/fa";

import Background from '../../media/hero/image2.jpeg';

import Landmarks from '../../media/about/landmarks.jpg';
import UrbanAdventures from '../../media/about/urbanadventures.jpg';
import AdventureTours from '../../media/about/adventuretours.jpg';
import LuxuryEscapes from '../../media/about/luxuryescapes.jpg';
import HiddenGems from '../../media/about/hiddengems.jpg';
import NaturalWonders from '../../media/about/naturalwonders.jpg';
import VibrantCultures from '../../media/about/vibrantcultures.jpg';

const CarouselInfoComponent = () => {

  
  const images = [
    Landmarks,
    UrbanAdventures,
    AdventureTours,
    LuxuryEscapes,
    HiddenGems,
    NaturalWonders,
    VibrantCultures
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 justify-center items-center relative" style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}>
    {/* Left Half - Carousel */}
  <div className="absolute inset-0 bg-black opacity-50">
  </div>

  <div className="col-span-1 flex justify-center items-center m-5 lg:m-20 relative z-10">
    <Carousel infiniteLoop autoPlay showStatus={false} showThumbs={false}>
      {images.map((image, index) => (
        <div key={index} className="h-[50vh] md:h-[60vh] lg:h-[80vh]" >
          <img src={image} alt={`Carousel Image ${index}`} className="w-full h-full object-cover" />
        </div>
      ))}
    </Carousel>
  </div>

  {/* Right Half - Sample Content */}
  <div className="col-span-1 m-5 lg:mr-20 text-white flex justify-center text-center lg:text-left items-center z-10">
  <div className="p-4">
    <h6 className="font-cursive mb-5 text-3xl md:text-xl font-bold lg:text-3xl line-clamp-3">Adventure Travel</h6>
    <h2 className="font-roboto-flex mb-5 text-3xl md:text-xl lg:text-5xl font-bold line-clamp-3">Embrace the Thrill of the Unknown</h2>
    <p className="font-poppins mb-5">Are you tired of the typical tourist destinations and looking to step out of your comfort zone? Adventure travel may be the perfect solution for you! Here are four reasons why you should book an adventure travel experience :</p>
    <div className="flex flex-col  justify-center items-center lg:justify-left lg:items-start">
      <div className="flex items-center space-x-2">
        <div className="flex items-center justify-center">
          <FaCheck  />
        </div>
        <p>Connect with nature</p>
      </div>
      <hr className="my-2 w-full border-t border-[#FFFFFF2E]" />
      <div className="flex items-center space-x-2">
        <div className="flex items-center justify-center">
          <FaCheck />
        </div>
        <p>Experience other cultures</p>
      </div>
      <hr className="my-2 w-full border-t border-[#FFFFFF2E]" />
      <div className="flex items-center space-x-2">
        <div className="flex items-center justify-center">
          <FaCheck />
        </div>
        <p>Challenge yourself and grow</p>
      </div>
      <hr className="my-2 w-full border-t border-[#FFFFFF2E]" />
      <div className="flex items-center space-x-2">
        <div className="flex items-center justify-center">
          <FaCheck />
        </div>
        <p>Create unforgettable memories</p>
        
      </div>
    </div>
  </div>
</div>


    
</div>

  );
};

export default CarouselInfoComponent;
