import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
export const AuthContext = createContext();

export const DJANGO_URL = 'http://172.16.16.10'


export const AuthProvider = ({ children }) => {
  const [openTimeOutDialog, setOpenTimeOutDialog] = useState(false);

  const [remainingTime, setRemainingTime] = useState(0);
  const inLocal = localStorage.getItem("session");


 
  const stored = JSON.parse(inLocal);
  const [accessToken, setAccessToken] = useState(stored?.access);

  useEffect(() => {
    if (inLocal) {
    
      setAccessToken(stored.access);
      setUserData(stored)
    }
  }, [localStorage.getItem("session")]);




  const [userData, setUserData] = useState(stored);





  const refreshAccessToken = async () => {
    try {
      const response = await axios.post(`${DJANGO_URL}/token/refresh/`, {
        refresh: stored?.refresh, 
      });
      const newAccessToken = response.data.access;
          const newRefreshToken = response.data.refresh;

      const session = JSON.parse(localStorage.getItem("session"));
      session.access = newAccessToken;
      session.refresh = newRefreshToken;
      localStorage.setItem("session", JSON.stringify(session));

      setAccessToken(newAccessToken);
      setOpenTimeOutDialog(false);
    } catch (err) {
      window.location.href = "/signin";
          localStorage.removeItem("session");
    }
  };



  useEffect(() => {
    const checkTokenExpiration = () => {
      if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;
        const expirationTime = decodedToken.exp;

        if (expirationTime - currentTime < 60) {
          setOpenTimeOutDialog(true);
        }

        if (decodedToken.exp < currentTime) {
          window.location.href = "/signin";
          localStorage.removeItem("session");
        }
        const remainingSeconds = expirationTime - currentTime;
        setRemainingTime(remainingSeconds);
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 60 * 1000);

    checkTokenExpiration();

    return () => clearInterval(intervalId);
  }, [accessToken, setOpenTimeOutDialog]);

  const handleLogout = () => {
    window.location.href = "/signin";

    localStorage.removeItem("session");
  };

  const handleIgnore = () => {
    setOpenTimeOutDialog(false);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        setAccessToken,
        handleLogout,
        userData,
        openTimeOutDialog,
        refreshAccessToken,
        handleIgnore,
        remainingTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};






