import React, { useEffect } from 'react';

const Snackbar = ({ message, severity, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); 
  }, [onClose]);

  const getSeverityStyles = () => {
    switch (severity) {
      case 'success':
        return {
          bgColor: 'bg-emerald-500',
          textColor: 'text-emerald-500',
          iconPath: 'M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z'
        };
      case 'info':
        return {
          bgColor: 'bg-blue-500',
          textColor: 'text-blue-500',
          iconPath: 'M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z'
        };
      case 'warning':
        return {
          bgColor: 'bg-yellow-400',
          textColor: 'text-yellow-400',
          iconPath:     'M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z'
        };
      case 'error':
        return {
          bgColor: 'bg-red-500',
          textColor: 'text-red-500',
          iconPath: 'M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z'
        };
      default:
        return {
          bgColor: 'bg-gray-500',
          textColor: 'text-gray-500',
          iconPath: ''
        };
    }
  };

  const { bgColor, textColor, iconPath } = getSeverityStyles();

  return (
    <div className="fixed top-0 inset-x-0 flex justify-center z-50 mt-4">
    
    <div className="flex justify-between">
      
      <div className={`flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800`}>
        <div className={`flex items-center justify-center w-12 ${bgColor}`}>
          <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d={iconPath} />
          </svg>
        </div>
      
          <div className="mx-3">
            <span className={`font-semibold ${textColor} dark:${textColor}`}>{severity.charAt(0).toUpperCase() + severity.slice(1)}</span>
            <p className="text-sm text-gray-600 dark:text-gray-200">{message}</p>
          </div>
     
       <div className={`flex items-center justify-center w-12 `}>
       
        <button onClick={onClose} className="text-gray-500 focus:outline-none">
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 8.586l3.293-3.293a1 1 0 111.414 1.414L11.414 10l3.293 3.293a1 1 0 01-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 01-1.414-1.414L8.586 10 5.293 6.707a1 1 0 011.414-1.414L10 8.586z" clipRule="evenodd" />
          </svg>
        </button>
      </div>  </div></div>
    </div>
  );
};

export default Snackbar;
