import React from "react";
import { Navbar } from "../others/Navbar";
import { Footer } from "../others/Footer";

const MainLayout = ({ children }) => {
  return (
    <>
     
      <main className="min-h-screen">{children}</main>
    
    </>
  );
};

export default MainLayout;
