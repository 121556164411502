import React, { useEffect, useState } from "react";
import { DJANGO_URL } from "../../utils/AuthContext";

import DateAvailable from "./dates";

const PhotoGalleryHeader = ({
  packageData,

}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);

  const showMoreThanFiveImages = packageData?.images?.length > 3;
  const extraImagesCount = packageData?.images?.length - 3;
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < packageData?.images?.length - 1 ? prevIndex + 1 : 0
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [packageData?.images?.length]);
  const goToPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const goToNextImage = () => {
    if (currentImageIndex < packageData?.images?.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleImageClick = (images) => {
    setModalImages(images);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < modalImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <>
      <div className="bg-white flex  z-10 flex-col md:flex-row">
        <div className="md:w-2/4 lg:w-2/3 p-4 lg:ml-10  relative">
          <div className="relative">
            <div className="relative  lg:h-[550px] md:h-[550px] ">
              <img
                onClick={() => handleImageClick(packageData?.images)}
                className="h-full w-full object-cover rounded-lg cursor-pointer"
                src={
                
                  packageData?.images[currentImageIndex].image
                }
                alt=""
              />
              {currentImageIndex > 0 && (
                <div
                  onClick={goToPreviousImage}
                  className="absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer bg-gray-100 rounded-full p-2"
                  style={{ borderRadius: "0 22px 22px 0" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                  </svg>
                </div>
              )}
              {currentImageIndex < packageData?.images?.length - 1 && (
                <div
                  onClick={goToNextImage}
                  className="absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer bg-gray-100 rounded-full p-2"
                  style={{ borderRadius: "22px 0 0 22px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 19.5 15.75 12l-7.5-7.5"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="md:w-2/4 lg:w-1/3 p-4 lg:mr-10">
          <div
            className={`grid grid-cols-4 gap-2 mt-4 ${
              showMoreThanFiveImages ? "flex items-center justify-center" : ""
            }`}
          >
            {packageData?.images?.slice(0, 3).map((image, index) => (
              <img
                key={index}
                onClick={() => setCurrentImageIndex(index)}
                className={`h-28 w-full object-cover rounded-lg cursor-pointer ${
                  index === currentImageIndex ? "border-2 border-blue-500" : ""
                }`}
                src={ image.image}
                alt=""
              />
            ))}
            {showMoreThanFiveImages && (
              <div
                className={`h-28 w-full object-cover rounded-lg cursor-pointer flex items-center justify-center text-gray-500 bg-gray-200`}
              >
                +{extraImagesCount} more
              </div>
            )}
          </div>
          <hr className="my-2 w-full border-t border-[#e4e4e4]" />
 
          <div className="relative flex flex-col items-center bg-[#FFFAE5] border border-[#FFD700] rounded-lg p-4 shadow-md my-6 animate-pop-out">
    
            <div className="absolute top-0 left-0 transform -translate-x-2 -translate-y-2 bg-red-500 text-white px-4 py-1 font-bold text-sm shadow-md ribbon animate-swing">
              Best Price
            </div>

            <p className="text-xl text-gray-700 font-semibold">Price starts from</p>
            <p className="text-3xl font-bold text-[#1bbc9b] mt-2">
              ₹{packageData?.price.toLocaleString('en-IN')}
            </p>
            <p className="text-sm text-gray-500 mt-1">
              per {packageData?.couple ?  "couple":"person"}
            </p>
          </div>
          {/* <hr className="my-2 w-full border-t border-[#e4e4e4]" /> */}

          <style jsx>{`
            @keyframes popOut {
              0% {
                transform: scale(0.5);
                opacity: 0;
              }
              100% {
                transform: scale(1);
                opacity: 1;
              }
            }

            @keyframes swing {
              0% {
                transform: rotate(0deg);
              }
              50% {
                transform: rotate(10deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }

            .animate-pop-out {
              animation: popOut 0.5s ease-in-out forwards;
            }

            .ribbon {
              clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 15% 100%);
            }

            .animate-swing {
              animation: swing 1s ease-in-out infinite;
            }
          `}</style>
        <div class=" top-20 p-4 bg-[#F5F8FF] border border-[#CFDDFF] rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700">
              <DateAvailable
              
              />
            </div>
            {/* <hr className="my-2 w-full border-t border-[#e4e4e4]" /> */}
        
            <div className=" mt-2 flex justify-center rounded-b-lg ">
            <a
              href={`mailto:info@flymyway.in?subject=Booking Inquiry&body=I'm interested in booking the ${packageData?.title} tour package. Please provide more details.`}
              className="bg-[#1bbc9b] text-white px-4 py-2  text-center w-full rounded hover:bg-[#16a085] "
            >
              Enquiry Now
            </a></div>
          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
              <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 text-white text-2xl"
              >
                {/* &times; */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <button
                onClick={handlePrevImage}
                disabled={currentImageIndex === 0}
                className={`absolute left-4 text-white text-2xl ${
                  currentImageIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <img
                src={ modalImages[currentImageIndex].image}
                alt="Fullscreen"
                className="max-w-full max-h-full"
              />
              <button
                onClick={handleNextImage}
                disabled={currentImageIndex === modalImages.length - 1}
                className={`absolute right-4 text-white text-2xl ${
                  currentImageIndex === modalImages.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PhotoGalleryHeader;
