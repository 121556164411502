import "./LoadingScreen.css"; 

export const LoadingScreen = () => (
  <div className="loading-screen">

    <div className="loading-text">
      <h1>Loading</h1>
    </div>
    <div className="loading-animation mt-5">
      <div className="dot dot1 text-blue-500"></div>
      <div className="dot dot2 "></div>
      <div className="dot dot3 bg-white"></div>
      <div className="dot dot4"></div>
      <div className="dot dot5"></div>
    </div>
  </div>
);