import React, { useState, useEffect, Fragment } from "react";
import {
  Disclosure,
  Dialog,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { DJANGO_URL, useAuth } from "../../utils/AuthContext";
import comingsoon from "../../media/comingsoon2.jpg";
import TopBar from "./Top";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Navbar = () => {
  const { userData, handleLogout } = useAuth();
  const [showTopBar, setShowTopBar] = useState(true);

  const renderProfilePictureOrAvatar = () => {
    if (userData?.profilepic) {
      return (
        <img
          className="h-8 w-8 rounded-full"
          src={DJANGO_URL + userData?.profilepic}
          alt="profile"
        />
      );
    } else {
      const initials = `${userData.first_name.charAt(0)}${userData.last_name.charAt(0)}`;
      return (
        <div className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center text-white font-bold">
          {initials}
        </div>
      );
    }
  };

  const location = useLocation();
  const [activeSection, setActiveSection] = useState("home");

  const navigation = [
    {
      name: "Home",
      href: "/#",
      current:
        activeSection === "home" &&
        location.pathname !== "/signin" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/profile",
    },
    
    {
      name: "Tour Package",
      href: "/#tour-package",
      current: activeSection === "tour-package",
    },
    {
      name: "Reviews",
      href: "/#reviews",
      current: activeSection === "reviews",
    },
    {
      name: "Contact Us",
      href: "/#contact-us",
      current:
        activeSection === "contact-us" &&
        location.pathname !== "/signin" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/profile",
    },
    location.pathname === "/signin" && {
      name: "Login",
      current: location.pathname === "/signin",
    },
    location.pathname === "/signup" && {
      name: "Signup",
      current: location.pathname === "/signup",
    },
    location.pathname === "/profile" && {
      name: "Profile",
      current: location.pathname === "/profile",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowTopBar(false);
      } else {
        setShowTopBar(true);
      }

      const sections = document.querySelectorAll("section");
      let prevSection = null;

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight) {
          prevSection = section.id;
        }
      });

      setActiveSection(prevSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [dialogOpen, setOpen] = useState(false);

  return (
    <>
      {showTopBar && <TopBar />}
      <Disclosure as="nav" className={`bg-gray-100 fixed ${showTopBar ? 'top-12 md:top-10' : 'top-0'} left-0 z-20 right-0`}>
      {({ open }) => (
          <>
            <div className="mx-auto  max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                {/* Code for mobile menu button */}
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>
                {/* Logo */}
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img className="h-12 w-auto" src="/flymywaylogo.png" alt="FlyMyWay Global Tours and Travels" />
                     {/* <span className="text-xl ml-1 font-semibold sm:text-2xl whitespace-nowrap">
                      FlyMyWay
                    </span> */}
                  </div>
                  {/* Main Navigation */}
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "text-[#1bbc9b]" : "text-black hover:text-[#1bbc9b]",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Menu as="div" className="relative ml-3">
                    {userData ? (
                      <MenuButton className="relative flex rounded-full border border-transparent bg-white text-sm focus:outline-none focus:ring-2 focus:border-blue-500 focus:ring-opacity-40 focus:ring-offset-2 focus:ring-blue-300">
                        {renderProfilePictureOrAvatar()}
                      </MenuButton>
                    ) : (
                      <>
                        {/* Your existing login/signup links here */}
                      </>
                    )}
                    {/* Profile Dropdown */}
                    <Transition
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <MenuItem>
                          {({ focus }) => (
                            <a
                              href="/profile"
                              className={`flex items-center ${classNames(
                                focus ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}`}
                            >
                              <svg
                                className="w-5 h-5 mx-1"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              <span className="mx-1">View Profile</span>
                            </a>
                          )}
                        </MenuItem>
                        <hr className="border-gray-200 " />
                        <MenuItem>
                          {({ focus }) => (
                            <a
                              className={`flex items-center ${classNames(
                                focus ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}`}
                              onClick={handleLogout}
                            >
                              <svg
                                className="w-5 h-5 mx-1"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              <span className="mx-1">Sign Out</span>
                            </a>
                          )}
                        </MenuItem>
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <Transition show={dialogOpen} as={Fragment}>
              <Dialog className="relative z-30" onClose={() => setOpen(false)}>
                <div className="fixed inset-0 bg-gray-100 bg-opacity-70 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel
                        className="relative flex items-center justify-center transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        style={{ maxWidth: "70vw" }}
                      >
                        <img
                          className="w-auto"
                          src={comingsoon}
                          alt="Your Company"
                          style={{ maxHeight: "80vh" }}
                        />
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
            <DisclosurePanel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <DisclosureButton
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? "bg-gray-900 text-white" : "hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </>
  );
};
