import React, { useState, useEffect, Fragment } from "react";

import "react-multi-carousel/lib/styles.css";

import { useLocation, useNavigate } from "react-router-dom";

import StickyNav from "./stickyheadernav";
import PhotoGalleryHeader from "./photogalleryheader";
import AdventureInfoCard from "./about";
import loadingGif from "../../media/loader/plane1.gif"; 

function LocationPage() {

  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;
  const dummyPackageData = state && state.dummyPackageData;
  useEffect(() => {
   if(!dummyPackageData){
    navigate("/");
   }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [dummyPackageData]);

  // const handleViewClick = () => {
  //   const words = packageData?.title.split(/\s+/);
  //   const formattedTitle = words.join("-");
  //   navigate(`/locations/${formattedTitle}/checkout`, {
  //     state: { packageData: packageData, tourData: tourData },
  //   });
  // };


  if (!dummyPackageData) {
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-white flex justify-center items-center flex-col z-[9999]">
        <div className="flex items-center">
          <img src={loadingGif} alt="Loading..." />
        </div>
      </div>
    );
  }
  return (
   <section id="tour-package">
       <div class="bg-gray-100 dark:bg-gray-800">
          <>
            <>
              <StickyNav packageData={dummyPackageData} />
              <PhotoGalleryHeader
                packageData={dummyPackageData}
                // dateAvailable={dateAvailable}
                // selectedDate={selectedDate}
                // setSelectedDate={setSelectedDate}
                // tourData={tourData}
              />
  <div class="flex ">
                  <div
                    className={`
                   w-full   p-4`}
                  >
                    <AdventureInfoCard
                
                      packageData={dummyPackageData}
                  
                    />
                  </div>
                  {/* {moment(tourData?.from_date).isSameOrAfter(moment(), "day") &&
                    tourData?.published_status === "Active" && (
                      <div className="hidden lg:block md:block  p-4">
                        <div className="bg-white  sticky top-36 border   rounded-lg mt-10 w-full ">
                          <BookingSummary
                            setShowTable={setShowTable}
                            showTable={showTable}
                            tourData={tourData} 
                            packageData={packageData}
                            setShowEnquiry={setShowEnquiry}
                            showEnquiry={showEnquiry}
                            priceDetails={priceDetails}
                            setPriceDetails={setPriceDetails}
                          />
                          <div className="mt-4 p-4 bg-[#29499A] flex justify-center rounded-b-lg ">
                            <button
                              disabled={userData?.is_admin}
                              onClick={() => {
                                if (!accessToken) {
                                  setShowSignin(true);
                                } else {
                                  handleViewClick();
                                }
                              }}
                              className="bg-yellow-500 text-white px-4  w-full   py-2 rounded-md"
                            >
                              {" "}
                              {userData?.is_admin
                                ? "Not Allowed For Admin"
                                : "PROCEED TO BOOK"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}

                  {/* <div className="lg:hidden md:hidden fixed bottom-0 left-0 right-0 p-4 bg-[#29499A]  justify-center ">
                    <div className="flex justify-between items-center mt-2">
                      <div className="flex flex-col">
                  
                        <p className="text-sm text-white">
                          Basic Price
                          
                  
                            </p>
                    
                 
            
                        <button
                          onClick={() => setShowTable(true)}
                          className="mb-4 text-blue-400  underline text-sm"
                        >
                          {"View Price Table"}
                        </button>
                      </div>

                      {accessToken && (<>
                       
                        <div className="flex flex-col text-white">

                        <button onClick={()=>setShowEnquiry(true)}
                    
                    class="flex items-center justify-center w-full px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 ">
                 
                    <span>Enquiry</span>
                </button>
                          {priceDetails?.discounted_total_cost > 0 ? (
                            <>
                              <p className="text-white">
                                <span className="line-through">
                                  ₹
                                  {priceDetails?.original_total_cost?.toLocaleString(
                                    "en-IN"
                                  )}
                                </span>
                              </p>
                              <h2>
                                ₹
                                {priceDetails?.total_amount?.toLocaleString(
                                  "en-IN"
                                )}
                              </h2>
                            </>
                          ) : (
                            <h2>
                              ₹
                              {priceDetails?.total_amount?.toLocaleString(
                                "en-IN"
                              )}
                            </h2>
                          )}
                        </div>
                        </>)}
                    </div>
                    <button
                      disabled={userData?.is_admin}
                      onClick={() => {
                        if (!accessToken) {
                          setShowSignin(true);
                        } else {
                          handleViewClick();
                        }
                      }}
                      className="bg-yellow-500 text-white px-4 py-2 rounded-md mt-4 w-full"
                    >
                     
                    </button>
                  </div> */}
                </div>
              
            </>
          </>

       
        </div>
      
    </section>
  );
}

export default LocationPage;
