import React, { useState } from 'react';

import { FaFire } from "react-icons/fa";
const StickyNav = ({packageData}) => {

  return (<>
     <div class="bg-white   z-10 mt-28 top-16 sticky shadow ">
     <div class='flex justify-between'>
      <h1 className="p-4 flex items-center lg:px-20 text-3xl mb-2">{packageData?.title} &nbsp;<FaFire
                    className=" animate-pulse text-red-500"
                    style={{
                      filter: "drop-shadow(0 0 5px rgba(255, 0, 0, 0.75))",
                    }}
                  /></h1>
      <div className="p-4 lg:px-20 mb-2">
     
      {/* <button 
                
                class="bg-green-500 text-white  font-bold py-2 px-4 rounded hover:bg-green-600">
                Book Now
              </button> */}
              </div>
        
        </div>   
    {/* <div class="container  bg-white lg:px-20 flex items-left justify-left  text-gray-600 capitalize dark:text-gray-300">
        <a  class="text-gray-800 transition-colors duration-300 transform dark:text-gray-200 border-b-2 border-blue-500 mx-1.5 sm:mx-6">Photos</a>

        <a  class="border-b-2 border-transparent hover:text-gray-800 transition-colors duration-300 transform dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6">About the Place</a>

        <a  class="border-b-2 border-transparent hover:text-gray-800 transition-colors duration-300 transform dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6">Inclusions</a>

        <a class="border-b-2 border-transparent hover:text-gray-800 transition-colors duration-300 transform dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6">Detailed Itinerary</a>

     
    </div> */}
</div>
  
    </>
  );
}

export default StickyNav;
