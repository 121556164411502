import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../media/bali.png'
import image2 from '../../media/bali1.jpg'
import image3 from '../../media/bali2.jpg'

import { FaFire } from 'react-icons/fa';
import image4 from '../../media/bali3.jpg'
import tourimage1 from '../../media/tourimage1.png'

import tour1 from '../../media/tourinternary/tour1.png'
import tour2 from '../../media/tourinternary/tour2.png'
import tour3 from '../../media/tourinternary/tour3.png'
import tour4 from '../../media/tourinternary/tour4.png'
import tour5 from '../../media/tourinternary/tour5.png'
import tour6 from '../../media/tourinternary/tour6.png'
import tour7 from '../../media/tourinternary/tour7.png'



import tour8 from '../../media/tourinternary/tour8.png'
import tour9 from '../../media/tourinternary/tour9.png'
import tour10 from '../../media/tourinternary/tour10.png'

import tour11 from '../../media/tourinternary/tour11.png'
export const PackageSection = () => {

  const navigate = useNavigate();

  // const fetchPackagesData = async () => {
  //   try {
  //     const response = await axios.get(`${DJANGO_URL}/packages/`);
  //     setPackagesData(response.data.data);
  //   } catch (err) {
  //     console.log(err, "err");
  //   }
  // };

  // useEffect(() => {
  //   fetchPackagesData();
  // }, []);
  const dummyPackageData = [
 



    {
      id: 4,
      title: "Singapore - Malaysia - Thailand (11D/10N)",
      view:true,
      fixedprice:true,
      price:139000,
      description: "We’re thrilled to present our exclusive 10 Nights / 11 Days  Experience the vibrant city-state of Singapore. From futuristic architecture to delicious street food, explore Gardens by the Bay and immerse yourself in the rich cultural heritage. Discover the tropical paradise of Thailand. Relax on pristine beaches, explore ancient temples, and indulge in delicious Thai cuisine. Immerse yourself in the cultural melting pot of Kuala Lumpur. Discover bustling markets, enjoy delicious street food, and visit iconic landmarks like the Petronas Towers.",
      package_images: [
        { image: "https://images.unsplash.com/photo-1600664356348-10686526af4f?q=80&w=1750&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
      
        { image: "https://images.unsplash.com/photo-1528181304800-259b08848526?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
      {image:tourimage1}
      ],
      inclusions: `  Airfare with taxes
3 Nights stay in Singapore at Chancellor Orchid or Similar with
Breakfast
3 Nights stay in Kuala Lumpur at Ibis KLCC on Similar with breakfast
Singapore city tour
Garden by the bay
Jurong Bird Park
Universal Studio
Kuala Lumpur city tour with KL Tower
Genting Day tour
Visa fees
Lunches and Dinner
All sightseeing and transfer
Alcazar Show
Coral Island
Nong Nooch
Safari World
Marine Park
Bangkok city tour
3 Night stay in Pattaya at golden beach
1 Night stay in bangkok at eco tell
Round-trip flight ticket`,

      exclusions: `GST 5% TCS 5%
      Any Expenses of Personal Nature
      Travel insurance `,
      itinerary:[
      
          {
            title: "Arrive Singapore Airport - Hotel",
            description: `
              <p>When you arrive at Singapore's airport, you should anticipate the same efficiency and kindness that the city-state is known for. Take some time to unwind and get ready after checking in so that you can begin your trip to Singapore feeling refreshed and invigorated.</p>
              <p>Take an evening visit to Gardens by the Bay to see the Flower Dome and Cloud Forest. Before you enter the Cloud Forest, an ethereal landscape with a cascading waterfall, explore the floral displays of the Floral Dome. The Super Tree Grove, which was inspired by Avatar, has illuminated vertical gardens that contrast strikingly with the night sky. This is a wonderful spot to come to an end.</p>
              <p><strong>Meals:</strong> Lunch and Dinner</p>
            `,
            image:tour1,
          },
          {
            title: "Sentosa Island And Jurong Bird Park",
            description: `
              <p>After a delicious breakfast, take a birdwatching excursion at Jurong Bird Park, which is home to a wide variety of bird species among lush vegetation and fascinating exhibits. Later, spend a fun-filled afternoon at Sentosa Island, where you can take a breathtaking cable car ride, see the SEA Aquarium's marine exhibits, and watch the captivating Wings of Time show, which promises to be a memorable fusion of entertainment and the stunning surrounding scenery.</p>
            `,
            image:tour2,
          },
          {
            title: "Universal Studio",
            description: `
              <p>After a delicious breakfast, take a birdwatching excursion at Jurong Bird Park, which is home to a wide variety of bird species among lush vegetation and fascinating exhibits. Later, spend a fun-filled afternoon at Sentosa Island, where you can take a breathtaking cable car ride, see the SEA Aquarium's marine exhibits, and watch the captivating Wings of Time show, which promises to be a memorable fusion of entertainment and the stunning surrounding scenery.</p>
            `,
            image:tour3,
          },
          {
            title: "Singapore - Kuala Lumpur",
            description: `
              <p>After a filling breakfast, take a scenic coach ride from your current location to Singapore, enjoying the beautiful vistas along the way. After your journey, head to your hotel in Kuala Lumpur to freshen up and unwind. Ensure a seamless arrival in Kuala Lumpur by making sure you check into your comfortable accommodation.</p>
              <p>You can explore the sights of the vibrant city because you have the rest of the evening to yourself. You can eat at some of the greatest restaurants in the area or take a stroll down the busy streets. Take advantage of this chance to relax and recharge while allowing yourself to take in Kuala Lumpur's culture and atmosphere at your own pace.</p>
            `,
            image:tour4,
          },
          {
            title: "Kuala Lumpur",
            description: `
              <p>After a delicious breakfast, take a birdwatching excursion at Jurong Bird Park, which is home to a wide variety of bird species among lush vegetation and fascinating exhibits. Later, spend a fun-filled afternoon at Sentosa Island, where you can take a breathtaking cable car ride, see the SEA Aquarium's marine exhibits, and watch the captivating Wings of Time show, which promises to be a memorable fusion of entertainment and the stunning surrounding scenery.</p>
            `,
            image:tour5,
          },
          {
            title: "Kuala Lumpur - Genting Highland - Kuala Lumpur",
            description: `
              <p>Take a Half Day Kuala Lumpur City Tour after breakfast to see famous sites including Merdeka Square and the Petronas Twin Towers. Experience Central Market's rich cultural legacy before climbing the Kuala Lumpur Tower for sweeping views of the city. Finish your tour with a leisurely walk along the colorful streets, taking in the colorful energy of Malaysia's bustling capital.</p>
            `,
            image:tour6,
          },
          {
            title: "Arrival in Bangkok",
            description: `
              <p>After breakfast, check out of the hotel and proceed to the airport for your flight to Bangkok.</p>
              <p><strong>Morning:</strong><br>Arrange for a pickup from Bangkok airport to Pattaya (approximately 2 hours drive). Check-in at your Pattaya hotel after 2 PM local time.</p>
              <p><strong>Evening:</strong><br>Attend the Alcazar Cabaret Show, a famous ladyboy performance known for its vibrant costumes and entertaining acts.</p>
              <p><strong>Meals:</strong> Lunch and Dinner</p>
            `,
            image:tour7,
          },
          {
            title: "Pattaya Exploration",
            description: `
              <p><strong>Morning:</strong><br>Visit Nong Nooch Village to explore its beautiful gardens, cultural shows, and elephant rides. The park also features traditional Thai performances and botanical gardens.</p>
              <p><strong>Afternoon:</strong><br>Enjoy lunch at Nong Nooch or at a local restaurant. Spend some time relaxing at Pattaya Beach or explore other local attractions like the Pattaya Floating Market.</p>
              <p><strong>Evening:</strong><br>Have dinner at a beachfront restaurant or in Pattaya's city center.</p>
              <p><strong>Meals:</strong> Breakfast, Lunch, and Dinner</p>
            `,
            image:tour8,
          },
          {
            title: "Pattaya to Coral Island",
            description: `
              <p><strong>Morning:</strong><br>Take a speedboat trip to Coral Island. Enjoy water activities such as snorkeling, parasailing, or relaxing on the beach. Lunch can often be arranged on the island or back in Pattaya.</p>
              <p><strong>Evening:</strong><br>Enjoy free time and walking around Pattaya's Walking Street.</p>
              <p><strong>Meals:</strong> Breakfast, Lunch, and Dinner</p>
            `,
            image:tour9,
          },
          {
            title: "Pattaya to Safari Bangkok",
            description: `
              <p><strong>Morning:</strong><br>Travel to Bangkok: Check out from your Pattaya hotel and travel to Bangkok. Visit Safari World and Marine Park, enjoying the safari drive-through, animal shows, and marine park attractions.</p>
              <p><strong>Afternoon:</strong><br>Have lunch at one of the park's restaurants.</p>
              <p><strong>Evening:</strong><br>Check into your Bangkok hotel and explore nearby attractions or enjoy a relaxing evening.</p>
              <p><strong>Meals:</strong> Breakfast, Lunch, and Dinner</p>
            `,
            image:tour10,
          },
          {
            title: "Bangkok City Tour and Departure",
            description: `
              <p><strong>Morning:</strong><br>Explore Bangkok's key attractions like the Grand Palace, Wat Pho (Temple of the Reclining Buddha), or Khao San Road.</p>
              <p><strong>Night:</strong><br>Have dinner at a local restaurant or at your hotel and enjoy your final night in Bangkok.</p>
              <p>Check out from your hotel and arrange for a transfer to Bangkok airport for your departure.</p>
              <p><strong>Meals:</strong> Breakfast and Lunch</p>
            `,
            image:tour11,
          }
          


    ]
    },


    {
      id: 2,
      couple:true,
      view:true,
      title: "Escape to Paradise: Bali Adventure (5D/4N)!",
      description: "We’re thrilled to present our exclusive 4 Nights / 5 Days Bali Special Package for the upcoming season! Perfect for your clients looking to experience the beauty and excitement of Bali, this offer includes everything they need for a memorable getaway.",
      package_images: [
      
        { image: image1 },  { image: image2 },  { image: image3 },  { image: image4 },
       ],

       price:28235,
       
       hotels:[
        {
          name: "Legian Paradiso",
          price: "INR 28,235 per couple",
          roomType: "Superior Room",
    
        },
        {
          name: "Quest Hotel Kuta",
          price: "INR 29,437 per couple",
          roomType: "Superior Room",
    
        },
        {
          name: "ZIA KUTA",
          price: "INR 30,039 per couple",
          roomType: "Superior Room",
     
        },
        {
          name: "Fave Sunset",
          price: "INR 29,476 per couple",
          roomType: "Fave Room",
       
        },
        {
          name: "Diamond Hotel",
          price: "INR 31,159 per couple",
          roomType: "Deluxe Non-Balcony",
    
        },
      ],
      inclusions: `  4 Nights accommodation with daily breakfast
      Private airport transfers and tours
      All entrance tickets and parking fees
      Traditional welcome and English-speaking driver
      Air-conditioned vehicle for all transfers and tours
      1 Banana Boat Ride  `,
      exclusions: `  Airfare & Taxes
      Foreign Tourist Levy IDR 150,000/Pax
      Visa & Insurance Charges
      Any upgrades in hotel room category
      Any meals other than specified above
      Any sightseeing other than mentioned
      Any entrance fees other than mentioned
      Any item of personal nature like tips, laundry, telephone calls, etc.  `,
      itinerary:[
        {
            title: "Arrival in Bali – Private transfer to hotel",
            description: "Start your vacation with a relaxing private transfer to your hotel."
        },
        {
            title: "Full day Benoa (1x Banana Boat Ride) + Uluwatu Tour",
            description: "Enjoy water sports and visit the famous Uluwatu Temple."
        },
        {
            title: "Full day Kintamani Volcano + Ubud Village + Ubud Royal Palace + Coffee Plantation",
            description: "Explore the volcanic region, visit Ubud, and experience local culture."
        },
        {
            title: "Full day Bedugul + Handara Gate + Tanah Lot",
            description: "Visit the scenic Bedugul area and the iconic Tanah Lot temple."
        },
        {
            title: "Departure – Private airport transfer",
            description: "End your journey with a comfortable private transfer to the airport."
        }
    ]
 
    },
    {
      id: 6,
      title: "Exotic Retreat in Thailand",
      description: "Discover the tropical paradise of Thailand. Relax on pristine beaches, explore ancient temples, and indulge in delicious Thai cuisine.",
      package_images: [
        { image: "https://images.unsplash.com/photo-1528181304800-259b08848526?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
          ]
    },
    
    // {
    //   id: 3,
    //   title: "Malaysian Delights in Kuala Lumpur",
    //   description: "Immerse yourself in the cultural melting pot of Kuala Lumpur. Discover bustling markets, enjoy delicious street food, and visit iconic landmarks like the Petronas Towers.",
    //   package_images: [
    //     { image: "https://plus.unsplash.com/premium_photo-1700955569575-439bed2d50f2?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    //      ]
    // },
    // {
    //   id: 7,
    //   title: "Himalayan Adventure in Nepal",
    //   description: "Embark on an unforgettable journey in Nepal. Trek through the majestic Himalayas, visit ancient temples in Kathmandu, and immerse yourself in the rich culture and hospitality of Nepal.",
    //   package_images: [
    //     { image: "https://images.unsplash.com/photo-1533289046496-8f2eef9e2e4b?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    //     { image: "https://images.unsplash.com/photo-1506197603052-3cc9c3a201bd?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    //     { image: "https://images.unsplash.com/photo-1565177010282-c496a6e1a6b5?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" }
    //   ]
    // }
    
  ];
  
  const transformedPackageData = dummyPackageData?.map((pkg, index) => {
    return {
      id: pkg.id,
      title: pkg.title,
      description: pkg.description,
      view:pkg.view,
      hotels:pkg.hotels,
      fixedprice:pkg.fixedprice,
      couple: pkg.couple,
      price:pkg.price,
      inclusions:pkg.inclusions,
      exclusions:pkg.exclusions,
      itinerary:pkg.itinerary,
      images: pkg.package_images || [],
      slideIndex: index
    };
  }) || [];

  const handleViewClick = (element) => {
    const words = element?.title.split(/\s+/);
    
    const rewords = words.map(word => word.replace(/[^a-zA-Z]/g, ''));
    const formattedTitle = rewords.join('-');
  
    navigate(`locations/${formattedTitle}`, { state: { packageId: element.id ,dummyPackageData: element } });
  };
  return (
    <section id="tour-package">
      <div className="bg-white ">
        <div className="container px-6 py-10 mx-auto relative z-10">
          <div className="text-black mx-auto mt-8 xl:mt-10 max-w-7xl">
            <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between mt-6">
              <div className="mb-10 lg:w-2/3 lg:text-center text-center md:mx-auto">
                <h6 className="font-cursive mb-2 text-lg md:text-xl text-[#1bbc9b] lg:text-3xl line-clamp-3">
                  Dream Vacation Destination
                </h6>
                <h2 className="font-roboto-flex mb-2 text-lg md:text-xl lg:text-5xl font-bold line-clamp-3">
                  Plan the Trip of a Lifetime with Ease
                </h2>
                <p className="text-[#6e6e6e]">
                  Whether you're looking for a romantic getaway, a family-friendly adventure, or a solo journey to explore the world, a flymyway can provide you with a custom-tailored itinerary that exceeds your expectations.
                </p>
              </div>
            </div>
           {transformedPackageData && 
            <div className="grid gap-4">
              <div className="relative group">
                {transformedPackageData[0]?.images?.length > 0 && (
                  <Carousel infiniteLoop autoPlay showStatus={false} showThumbs={false}>
                    {transformedPackageData[0]?.images.map((image, index) => (
                      <img
                        key={index}
                        className="h-[80vh] w-full rounded-lg"
                        src={ image.image}
                        alt="Featured"
                      />
                    ))}
                  </Carousel>
                )}  <FaFire
                className="absolute text-3xl top-0 right-0 animate-pulse text-red-700"
                style={{ filter: 'drop-shadow(0 0 5px rgba(255, 0, 0, 0.75))' }}
              />
                <div className="absolute bottom-2 left-2 text-white text-xl bg-black bg-opacity-50 px-2 py-1 rounded-md">
                  {transformedPackageData[0]?.title}
                </div>
                <div className="absolute inset-0 bg-white bg-opacity-80 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <p className="text-center text-xl font-extrabold px-4 line-clamp-5" dangerouslySetInnerHTML={{ __html: transformedPackageData[0]?.description }}></p>
                <button className="mt-4 px-4 py-2 text-white bg-[#1bbc9b] rounded-lg" onClick={() => handleViewClick(transformedPackageData[0])}>
                    View Details
                  </button> 
                </div>
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {transformedPackageData.slice(1, 4).map((pkg, index) => (
                  <div className="relative group" key={index}>
                    {pkg.images.length > 0 && (
                      <img
                        className="h-64 w-full object-cover rounded-lg cursor-pointer"
                        src={ pkg.images[0]?.image}
                        alt={`Gallery image ${index + 1}`}
                      />
                    )}
                    <div className="absolute bottom-2 left-2 bg-opacity-50 text-xl bg-black px-2 py-1 rounded-md">
                      <h2 className="text-white">{pkg?.title}</h2>
                    </div>
                    <div className="absolute inset-0 bg-white bg-opacity-80 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <p className="text-center px-4 font-extrabold line-clamp-3" dangerouslySetInnerHTML={{ __html: pkg?.description }}></p>
                      {pkg?.view && pkg?.view === true  && (<button onClick={() => handleViewClick(pkg)} className="mt-4 px-4 text-white py-2 bg-[#1bbc9b] rounded-lg">
                        View Details
                      </button>)}
                    </div>
                  </div>
                ))}
                {transformedPackageData.length > 4 && (
                  <div className="h-64 w-full flex items-center justify-center text-gray-500 bg-gray-200 rounded-lg cursor-pointer">
                    +{transformedPackageData.length - 4} more
                  </div>
                )}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PackageSection;
    {/* <button 
                
                class="bg-green-500 text-white  font-bold py-2 px-4 rounded hover:bg-green-600">
                Book Now
              </button> */}