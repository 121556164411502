import React, { useState } from "react";
import Timeline from "./Timeline";


export default function AdventureInfoCard({ packageData }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [activeTab, setActiveTab] = useState("inclusions");

  const toggleOverview = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const hotels = [
  //   {
  //     name: "Legian Paradiso",
  //     price: "INR 28,235 per couple",
  //     roomType: "Superior Room",

  //   },
  //   {
  //     name: "Quest Hotel Kuta",
  //     price: "INR 29,437 per couple",
  //     roomType: "Superior Room",

  //   },
  //   {
  //     name: "ZIA KUTA",
  //     price: "INR 30,039 per couple",
  //     roomType: "Superior Room",
 
  //   },
  //   {
  //     name: "Fave Sunset",
  //     price: "INR 29,476 per couple",
  //     roomType: "Fave Room",
   
  //   },
  //   {
  //     name: "Diamond Hotel",
  //     price: "INR 31,159 per couple",
  //     roomType: "Deluxe Non-Balcony",

  //   },
  // ];
  // const inclusions = 
  // `  4 Nights accommodation with daily breakfast
  //    Private airport transfers and tours
  //    All entrance tickets and parking fees
  //    Traditional welcome and English-speaking driver
  //    Air-conditioned vehicle for all transfers and tours
  //    1 Banana Boat Ride  `;

  // const exclusions = 
  // `  Airfare & Taxes
  //    Foreign Tourist Levy IDR 150,000/Pax
  //    Visa & Insurance Charges
  //    Any upgrades in hotel room category
  //    Any meals other than specified above
  //    Any sightseeing other than mentioned
  //    Any entrance fees other than mentioned
  //    Any item of personal nature like tips, laundry, telephone calls, etc.  `;

  const notes = 
  ` High season surcharges applicable
    Rates subject to change without prior notice
    Rooms/Seats subject to availability
    Itineraries subject to change
    Cancellation charges applicable as per company policy`;

  const paymentPolicy = 
  ` Rate of Exchange as per prevailing rate on the day/date of payment
    100% amount must be cleared 25 days before guest arrival in Bali`;

  const cancellationPolicy = 
  ` Cancellation fee applicable based on days prior to departure
    No-show or Early Departure incurs full cancellation fee
    21-30 Days prior to arrival – 50% of total tour price refunded
    30-45+ Days prior to arrival – 75% of total tour price refunded`;
//   const itinerary = [
//     {
//         title: "Arrival in Bali – Private transfer to hotel",
//         description: "Start your vacation with a relaxing private transfer to your hotel."
//     },
//     {
//         title: "Full day Benoa (1x Banana Boat Ride) + Uluwatu Tour",
//         description: "Enjoy water sports and visit the famous Uluwatu Temple."
//     },
//     {
//         title: "Full day Kintamani Volcano + Ubud Village + Ubud Royal Palace + Coffee Plantation",
//         description: "Explore the volcanic region, visit Ubud, and experience local culture."
//     },
//     {
//         title: "Full day Bedugul + Handara Gate + Tanah Lot",
//         description: "Visit the scenic Bedugul area and the iconic Tanah Lot temple."
//     },
//     {
//         title: "Departure – Private airport transfer",
//         description: "End your journey with a comfortable private transfer to the airport."
//     }
// ];

  return (
    <div className="bg-white border p-10 mt-10">
      {packageData && (
        <div>
          <h1 className="font-bold text-3xl text-center font-cursive text-[#1bbc9b] uppercase">
            {packageData?.name}
          </h1>

          <hr className="my-2" />
          <h2 className="font-bold text-2xl mb-4">Overview</h2>
          <p
            className={`text-gray-600 ${showFullDescription ? "" : "line-clamp-3"}`}
            dangerouslySetInnerHTML={{ __html: packageData?.description }}
          ></p>
          {packageData?.description.split("\n").length > 0 && (
            <button onClick={toggleOverview} className="text-blue-500">
              {showFullDescription ? "Show Less" : "Show More"}
            </button>
          )}

{packageData?.hotels && (<>
<hr className="my-10" />
      {/* <h3 className="font-bold text-2xl mb-4">Tour Rates with preferred Hotel</h3>
      <p className="text-[#6e6e6e] mb-4">Choose your preferred hotel from the list below.</p> */}

      <h3 className="font-bold text-2xl mb-4">Tour Rates with Preferred Hotels</h3>
<p className="text-[#6e6e6e] mb-4">Choose your preferred hotel from the list below.</p>

<div className="overflow-x-auto">
  <table className="min-w-full table-auto border-collapse border border-gray-300">
    <thead>
      <tr className="bg-gray-100">
        <th className="border border-gray-300 px-4 py-2 text-left">Hotel</th>
        <th className="border border-gray-300 px-4 py-2 text-left">Room Type</th>
        <th className="border border-gray-300 px-4 py-2 text-left">Tour Price</th>
      </tr>
    </thead>
    <tbody>
      { packageData?.hotels.map((hotel, index) => (
        <tr key={index} className="hover:bg-gray-50">
          <td className="border border-gray-300 px-4 py-2">{hotel.name}</td>
          <td className="border border-gray-300 px-4 py-2">{hotel.roomType}</td>
          <td className="border border-gray-300 px-4 py-2">{hotel.price}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div></>)

}

          <>
            <hr className="my-10" />
            <h3 className="font-bold text-2xl mb-4">Included / Excluded</h3>
            <p class="text-[#6e6e6e] mb-10">
            To help you plan your trip, we have put together a list of what's
            included and what's not included in your tour package. This will
            give you a clear understanding of what to expect and help you make
            any necessary arrangements before your journey begins.
          </p>
           <div className="flex w-full">
              <button
                className={`inline-flex items-center mr-2 justify-center flex-grow ${
                  activeTab === "inclusions"
                    ? "bg-[#29499a] text-white border-b border-[#29499a]"
                    : "bg-blue-200 text-[#29499a80]"
                } h-12 px-2 py-2 sm:px-4 whitespace-nowrap focus:outline-none rounded-t-md`}
                onClick={() => handleTabClick("inclusions")}
              >
                <span className="text-sm sm:text-base">Tour Inclusions</span>
              </button>
              <button
                className={`inline-flex items-center justify-center flex-grow ${
                  activeTab === "exclusions"
                    ? "bg-[#29499a] text-white border-b border-[#29499a]"
                    : "bg-blue-200 text-[#29499a80]"
                } h-12 px-2 py-2 sm:px-4 whitespace-nowrap focus:outline-none rounded-t-md`}
                onClick={() => handleTabClick("exclusions")}
              >
                <span className="text-sm sm:text-base">Tour Exclusions</span>
              </button>
            </div>

            <div id="tab-content" className="border-t border-[#29499a]">
  {activeTab === "inclusions" && (
    <div className="grid grid-cols-1 gap-4 border border-lg rounded-b-lg">
      <div className="p-5 text-base">
        <ul className="list-disc list-inside">
          {packageData?.inclusions.split("\n").map((item, index) => (
            <li key={index} className="text-green-600">{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )}

  {activeTab === "exclusions" && (
    <div className="grid grid-cols-1 gap-4 border border-lg rounded-b-lg">
      <div className="p-5 text-base">
        <ul className="list-disc list-inside">
          {packageData?.exclusions.split("\n").map((item, index) => (
            <li key={index} className="text-red-600">{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )}
</div>


            <hr className="my-10" />
            <h3 className="font-bold text-2xl mb-4">Itinerary</h3>
            <p className="text-[#6e6e6e] mb-10">
            We have carefully planned out each day to give you the best possible
            experience. From exploring historic landmarks to tasting delicious
            local cuisine, each day is packed with adventure and excitement.
            Join us as we take you on a journey through some of the most
            fascinating destinations in the world.   </p>
            <div className="p-4">
            <Timeline itinerary={packageData?.itinerary} />

            </div>

              <>
                <hr className="my-10" />
                <div className="mt-10">
                  <h3 className="font-bold text-2xl mb-4">Terms & Condition</h3>
                  <div className=" text-base">
        <ul className="list-disc list-inside">
          {notes.split("\n").map((item, index) => (
            <li key={index} className="text-gray-600">{item}</li>
          ))}
        </ul>
      </div>
    
                  {/* <pre className="text-gray-500 ">{notes}</pre> */}
                </div>
              </>


              <hr className="my-10" />
            <div className="mt-10">
              <h3 className="font-bold text-2xl mb-4">Payment Policy</h3>
    
              <div className=" text-base">
        <ul className="list-disc list-inside">
          {paymentPolicy.split("\n").map((item, index) => (
            <li key={index} className="text-gray-600">{item}</li>
          ))}
        </ul>
      </div>
    
              {/* <pre className="text-gray-500">{paymentPolicy}</pre> */}
            </div>

            <hr className="my-10" />
            <div className="mt-10">
              <h3 className="font-bold text-2xl mb-4">Cancellation Policy</h3>
              <div className=" text-base">
        <ul className="list-disc list-inside">
          {cancellationPolicy.split("\n").map((item, index) => (
            <li key={index} className="text-gray-600">{item}</li>
          ))}
        </ul>
      </div>
              {/* <pre className="text-gray-500">{cancellationPolicy}</pre> */}
              <hr className="my-10" />
          <div className="mt-10 text-center">
            <h3 className="font-bold text-xl mb-4">
              Don't miss out on this incredible opportunity to provide with the magic of {packageData?.title} let them create unforgettable memories.
            </h3>
            <div className=" flex justify-center rounded-b-lg "> <a
             href={`mailto:info@flymyway.in?subject=Booking Inquiry&body=I'm interested in booking the ${packageData?.title} tour package. Please provide more details.`}
             className="bg-[#1bbc9b] text-white px-4 py-2 rounded hover:bg-[#16a085] w-[50vw]"
            >
              Enquiry Now
            </a></div>
          </div>
        </div>
          </>
        </div>
      )}
    </div>
  );
}
