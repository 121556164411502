import React, { useState, useEffect } from 'react';
import Background from '../../media/testimonials/testibg.jpeg';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import comingsoon from '../../media/comingsoon.jpg'
import image from '../../media/testimonials/image.jpeg';
import Reviews from './reviews';
import { DJANGO_URL, useAuth } from '../../utils/AuthContext';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import { Disclosure,Dialog, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Fragment } from 'react';
const Testimonials = () => {
  // const [testimonialsData, setTestimonialsData] = useState(null);
  const { accessToken } = useAuth();
  const navigate = useNavigate();
  const [dailogopen, setOpen] = useState(false)
  const [reviews, setReviews] = useState(false);
  // const fetchTestimonialsData = async () => {
  //   try {
  //     const response = await axios.get(`${DJANGO_URL}/ratings/`);
  //       // const sortedData = response.data.sort((a, b) => b.ratings - a.ratings);
  //     setTestimonialsData(response.data.data);
  //   } catch (err) {
  //     console.log(err, "err")
  //   }
  // };

  // useEffect(() => {
  //   fetchTestimonialsData();
  // }, []);

  const renderProfilePictureOrAvatar = (testimonial) => {
    if (testimonial?.profileimage) {
      return <img className="w-14 h-14 rounded-full" src={ testimonial?.profileimage} alt="profile" />;
    } else {
      const initials = `${testimonial?.first_name.charAt(0)}${testimonial?.last_name.charAt(0)}`;
      return (
        <div className="w-14 h-14 rounded-full bg-green-500 flex items-center justify-center text-white font-bold">
          {initials}
        </div>
      );
    }
  };

  const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <svg
          key={i}
          className={`w-6 h-6 cursor-pointer ${i <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M10.049 1.268a1 1 0 011.902 0l2.17 4.39 4.84.704a1 1 0 01.554 1.706l-3.5 3.417.825 4.815a1 1 0 01-1.45 1.054L11 15.347l-4.33 2.275a1 1 0 01-1.45-1.054l.825-4.815-3.5-3.417a1 1 0 01.554-1.706l4.84-.704 2.17-4.39z" />
        </svg>
      );
    }
    return stars;
  };
  const [testimonialsData] = useState([
    {
      id: 1,
      first_name: 'Poonam',
      last_name: 'Jain',
      job_title: 'Traveler',
      profileimage: 'https://randomuser.me/api/portraits/women/68.jpg',
      ratings: 5,
      reviews: "The trip was very smooth, memorable and well planned. It was very well conducted. Megha was an exceptional manager with immense knowledge, patience, and she knew how to hold the interest of the travelers. Adorable and cultured, knowing how to react to people and situations in a most befeating manner. The food, drinks, and snacks were very good and planned well. Overall, as this was my first trip, I came back with tons of memories and a very good impression. Thanks Poonam Jain.",
      rating_images: [
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
        { image: 'https://images.unsplash.com/photo-1519985176271-adb1088fa94c' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
        { image: 'https://images.unsplash.com/photo-1519985176271-adb1088fa94c' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
    {
      id: 2,
      first_name: 'John',
      last_name: 'Doe',
      job_title: 'Adventurer',
      profileimage: 'https://randomuser.me/api/portraits/men/32.jpg',
      ratings: 4,
      reviews: "The trip exceeded my expectations! The destinations were breathtaking and the itinerary was well organized. Our guide was knowledgeable and friendly. I can't wait to book my next trip with this company.",
      rating_images: [
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
        { image: 'https://images.unsplash.com/photo-1519985176271-adb1088fa94c' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
        { image: 'https://images.unsplash.com/photo-1519985176271-adb1088fa94c' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
    {
      id: 3,
      first_name: 'Sarah',
      last_name: 'Connor',
      job_title: 'Explorer',
      profileimage: 'https://randomuser.me/api/portraits/women/44.jpg',
      ratings: 5,
      reviews: "This was the best travel experience I've ever had! The places we visited were breathtaking, and the travel arrangements were flawless. The guide was very knowledgeable and made the trip even more enjoyable. Highly recommend!",
      rating_images: [
        { image: 'https://images.unsplash.com/photo-1527153907022-465ee4752fdc' },
    
        { image: 'https://images.unsplash.com/photo-1516574187841-cb9cc2ca948b' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
    {
      id: 4,
      first_name: 'Michael',
      last_name: 'Smith',
      job_title: 'Traveler',
      profileimage: 'https://randomuser.me/api/portraits/men/75.jpg',
      ratings: 3,
      reviews: "The trip was good but there were a few hiccups along the way. Some of the accommodations could have been better. However, the destinations were beautiful and the guide was very friendly.",
      rating_images: [
        { image: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7' },
        { image: 'https://images.unsplash.com/photo-1499364615650-ec38552f4f34' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
    {
      id: 5,
      first_name: 'Emily',
      last_name: 'Brown',
      job_title: 'Tourist',
      profileimage: 'https://randomuser.me/api/portraits/women/58.jpg',
      ratings: 4,
      reviews: "Overall, a wonderful trip with some unforgettable memories. The tour was well-organized and the guide was very knowledgeable. I would definitely recommend it to my friends.",
      rating_images: [
        
        { image: 'https://images.unsplash.com/photo-1472457897821-70d3819a0e24' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
    {
      id: 6,
      first_name: 'David',
      last_name: 'Williams',
      job_title: 'Explorer',
      profileimage: 'https://randomuser.me/api/portraits/men/64.jpg',
      ratings: 5,
      reviews: "An amazing adventure! Everything was perfect from start to finish. The itinerary was well-planned and the guide was excellent. Highly recommended!",
      rating_images: [
        
        { image: 'https://images.unsplash.com/photo-1499364615650-ec38552f4f34' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
    {
      id: 7,
      first_name: 'Sophia',
      last_name: 'Johnson',
      job_title: 'Traveler',
      profileimage: 'https://randomuser.me/api/portraits/women/90.jpg',
      ratings: 4,
      reviews: "A well-organized trip with a friendly guide. The destinations were beautiful, and we had plenty of time to explore each place. I enjoyed every moment!",
      rating_images: [
        { image: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7' },
        { image: 'https://images.unsplash.com/photo-1499364615650-ec38552f4f34' },
        { image: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0' },
      ],
    },
  ]);
  
  return (
    <section id="reviews">

<div className=" p-4 "  >
    <div className="mb-10  lg:w-2/3 text-center md:mx-auto">
    <p className="text-lg text-gray-700 mb-4">
        Join us and make a habit of breaking from the routine. Rediscover the joy of travel and create memories that last a lifetime. Because vacations aren't a luxury – they're a necessity.
      </p>
      <a onClick={() => setOpen(true)} className="bg-blue-600 text-white py-3 px-6 rounded-lg text-lg">Join Now</a>
    </div></div>
    <div className="bg-white relative" style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container px-6 py-10 mx-auto relative z-10">
        <div className="text-white mx-auto mt-8 xl:mt-10 max-w-7xl">
          <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between mt-6">
            <div>
              <h1 className="justify-center text-center text-white lg:text-left items-center font-roboto-flex mb-5 text-3xl md:text-xl lg:text-5xl font-bold line-clamp-3">
                Unforgettable Travel Experiences
              </h1>
              <p className="max-w-2xl text-center  text-white lg:text-left  mb-6 lg:mb-0">
                Our customer's feedback is essential in building a great reputation and maintaining excellent service. By listening to our customer's needs, we can improve our offerings and provide an exceptional travel experience.
              </p>
            </div>
            <img className="object-cover rounded-full w-40 h-40 lg:w-48 lg:h-48 transform transition-transform duration-300" src={image} alt="Travel" />
          </div>
          {testimonialsData && (
            <>
              <Carousel infinite={true} autoPlay={true} autoPlaySpeed={3000} itemClass="carousel-item lg:mx-2" responsive={{ superLargeDesktop: { breakpoint: { max: 4000, min: 1600 }, items: 2 }, desktop: { breakpoint: { max: 1599, min: 1024 }, items: 2 }, tablet: { breakpoint: { max: 1023, min: 464 }, items: 1 } , mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
  }}}>
                {testimonialsData.slice(0, 10).map(testimonial => (
                  
                  
                  
                  
                  <div key={testimonial.id} className="relative p-6 mt-4 bg-[#1bbc9b] rounded-lg md:p-8 h-60 overflow-hidden flex flex-col">
  
  <div className="flex items-center mb-4">
    {renderProfilePictureOrAvatar(testimonial)}
    <div className="mx-4">
      <h1 className="font-semibold">{testimonial.first_name} {testimonial.last_name}</h1>
      <span className="text-sm ">{testimonial.job_title}</span>
    </div>
    {renderRatingStars(testimonial.ratings)}
  </div>

 
  <p className="line-clamp-3">
    {testimonial.reviews}
  </p>

  <div className="absolute p-5 bottom-0 right-0 flex items-center mb-2">
   
   
  {testimonial.rating_images.slice(0, 5).map((image, index) => (
          
   
            <div key={index} className="relative h-8 w-8">
            <img
                className={`h-8 w-8 ${testimonial.rating_images.length > 1 ? '-mx-1.5' : ''} ring ring-white  rounded-full object-cover`}
                src={image.image}
                alt={`Selected ${index + 1}`}
            />
           
        </div>
    ))}
    {testimonial.rating_images.length > 4 && (
        <div className="w-8 h-8 rounded-full bg-gray-200 ring ring-white flex items-center justify-center text-white font-bold">
            {`+${testimonial.rating_images.length - 4}`}
        </div>
    )}
   
     </div>
</div>

                ))}
              </Carousel>
             
            </>
          )}
        </div>
      </div>
      <Transition show={dailogopen} as={Fragment}>
  <Dialog className="relative z-30" onClose={() => setOpen(false)}>
    <div className="fixed inset-0 bg-gray-100 bg-opacity-70 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel
            className="relative flex items-center justify-center transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            style={{ maxWidth: '70vw' }}
          >
            <img
              className=" w-auto"
              src={comingsoon}
              alt="Your Company"
              style={{ maxHeight: '80vh' }}
            />
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>
      {/* <Reviews reviews={reviews} setReviews={setReviews} fetchTestimonialsData={fetchTestimonialsData}/> */}
    </div></section>
  );
};

export default Testimonials;
