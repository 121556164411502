import React from "react";
import { Link } from "react-router-dom";
import { FaPhone, FaEnvelope, FaLock, FaUserPlus } from "react-icons/fa";
import { FaFacebook, FaTwitter, FaDiscord } from "react-icons/fa";

const TopBar = () => {
  return (
    <div className="bg-gray-800 text-white text-sm py-2 px-4 sm:px-6 lg:px-8 fixed top-0 left-0 w-full z-30 h-12 md:h-10">
      <div className="flex justify-between items-center max-w-7xl mx-auto">
        <div className="flex  flex-col md:space-x-10 md:flex-row">
          <span className="flex items-center">
            <FaPhone className="mr-2" />
            +91 8110013999, +91 7373661441
          </span>
          <span className="flex items-center">
            <FaEnvelope className="mr-2" />
            info@flymyway.in
          </span>
        </div>
        <div className=" items-center space-x-4  hidden sm:flex">
          <a  className="hover:text-gray-400">
            <FaFacebook />
          </a>
          <a  className="hover:text-gray-400">
            <FaTwitter />
          </a>
          <a  className="hover:text-gray-400">
            <FaDiscord />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
